/* Moved from vendor.scss to be able to use the foundation variables and mix-ins */
$small-font-size: 70%;
@import '_settings.scss'; // Default settings file for foundation-sites, we also use it here
@import '_infostars-settings.scss'; // Customized variables for foundation-sites, we also use it here
@import 'foundation-sites/scss/normalize.scss';
@import 'foundation-sites/scss/foundation.scss'; // We include this here (instead of in vendors.scss), as we need calculations and mix-ins from it here and it collised with foundation-apps when included from the same main scss file (from angular.json). In this case e.g. the grid scss would not be included as the @mixing export() defined by foundation apps would tell foundation-sites that it's already included
@import './mixins.scss'; // We use it here

// Settings that depend on foundation variables go here
$assets-associated-color: $success-color;
$assets-missing-color: $alert-color;

// Fixes white bar at the bottom of the auto-hiding address bar on Firefox and Samsung mobile
// https://github.com/mozilla-mobile/fenix/issues/8768
html, body {
	height: auto !important;
}

// Support for angular.io style of ng-hide / ng-show, HTML5 doesn't know the hidden attributes on all elements, so for some we need to help it with CSS
div, small, p, a, img, ul, li, span, form, input, button, th, td {
	&[hidden], &.hidden {
		display: none !important;
	}
}

.row.full-width {
	width: 100%;
	max-width: 100%;
}

*:not([ng-click]) {
	cursor: auto;
}
.clickable, *[ng-click] {
	cursor: pointer;
}

.panel {
	margin-top: 50px;
}

table.full-width {
	width: 100%;
	word-break: break-all; // Prevent tables from overflowing to the right, when there are words in the cells that are too long. break-all will insert a line break within a word if required.
	&.no-word-break {
		word-break: initial;
	}
	td.no-line-break, th.no-line-break { // Support setting no-word/line break for individual columns
		word-break: keep-all;
		white-space: nowrap;
	}
	td, th {
		.no-line-break { // Support setting no-word/line break for individual column parts
			word-break: keep-all;
			white-space: nowrap;
		}
	}
}

input.inline, label.inline {
	vertical-align:middle;
	margin-bottom:0px;
}
input[type=phone] { // foundation 5 does not support the type="phone" input yet, apply styles as in _forms.scss:419
	@include form-element;
}

.label.linewrap {
	white-space: revert;
}

.top-bar {
	max-width: none;
}
.grid-block.navbar { // Make sure the subnav popouts are visible
	overflow: visible;
	> .grid-content {
		overflow: visible;
	}
}

.content-resizer {
	background-color: $shiny-edge-active-color;
	&.horizontal {
		height: rem-calc(5);
		cursor: n-resize;
	}
	&.vertical {
		width: rem-calc(5);
		cursor: w-resize;
		position: absolute;
		right: 0;
		height: 100%;
		z-index: 10;
	}
}

.loading-spinner-space, .no-results-space, .filter-limit-space {
	// the container is position: absolute, when used in non-100% height containers, we need to make sure we actually have vertical space to display in
	min-height: rem-calc(100);
	position: relative; // Make sure e.g. filter above .no-results-container still has space
}

.permission-denied-container, .loading-spinner-container, .no-results-container, .filter-limit-container {
	display: flex;
	justify-content: center;
	position: absolute; top: 0; bottom: 0; left: 0; right: 0;
	min-height: rem-calc(100);
	.permission-denied-notice, .loading-spinner, .no-results, .filter-limit-notice {
		align-self: center; text-align: center;
		color: $small-font-color;
		i {
			font-size: 300%;
		}
	}
	.loading-spinner {
		position: relative;
		i {
			position: absolute; // Required for mozilla animation to work
			top: -1em;
			-webkit-animation: spin 4s linear infinite;
			-moz-animation: spin 4s linear infinite;
			animation: spin 4s linear infinite;
		}
	}
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

#agbModal {
	p.itemized {
		margin-left: 3em;
		position: relative;
		span.item {
			position: absolute;
			left: -3em;
		}
	}
}
#hotkeysModal {
	.modifier + .modifier::before { // Between modifiers
		content: " + ";
	}
	.sequence + .sequence::before { // Between sequence hotkeys
		content: " → ";
	}
}
@mixin hotkeys-hint-dimmed-spacing {
	margin: 0;
	padding: 0 0.2em;
}
.hotkeys-hint, .hotkeys-hint-spacer {
	display: none; // No hotkeys on small and medium devices (probably mobile)
}
@media #{$medium-up} { // Hotkey hints only on desktop devices
	.hotkeys-hint {
		display: initial;
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 0.5em;
		margin: 0.5em;
		background-color: rgba($primary-color, 0.2);
		border-radius: 0.5em;
		backdrop-filter: blur(0.2em);
		transition: margin 0.8s, padding 0.8s, opacity 0.8s, background-color 0.8s;
		&, .content { // Add rules to make the element invisible for the mouse
			pointer-events: none;
			user-select: none;
		}
		&.dimmed {
			opacity: 0.6;
			@include hotkeys-hint-dimmed-spacing();
			background-color: transparent;
		}
	}
	.hotkeys-hint-spacer {
		display: initial;
		@include hotkeys-hint-dimmed-spacing();
		&::after {
			content: "\a0"; // &nbsp
		}
	}
}
.map-provider-popup {
	overflow: hidden; // Prevent scrollbars in the google maps info window http://stackoverflow.com/questions/19954893/google-map-infowindow-displays-scroll-bars-in-google-chrome
}
google-map {
	position: absolute;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	left: 0; right: 0; top: 0; bottom: 0;
	.map-container {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
}

.messages-container {
	position: absolute;
	width: 100%;
	z-index: 1000;
	.alert-box { margin-bottom: 0; }
	a { color: $header-font-color; }
}

table[role=grid] tr {
	td {
		&.actions, &.icon, &.date {
			vertical-align: middle;
			.button {
				margin-bottom: 0;
			}
		}
		&.actions {
			text-align: right;
			white-space: nowrap;
		}
	}
}

// A css table for layout purposes
.grid-table {
	display: table;
	&.column, &.columns {
		float: none !important;
	}
	> .row:before, > .row:after {
		content: none;
	}
	> .row {
		display: table-row;
		> .columns, > .column {
			display: table-cell;
			width: auto;
			float: none;
			vertical-align: top;
		}
	}
}

// Global css for data tables
table > tbody > tr > td,
table > thead > tr > th {
	.modified-by ~ .modified-at,
	.from-date, .to-date, .account-name, .post-code,
	.timezone, .iccid, .device-type, .status-summary,
	.device-firmware, .status-group, .routine-type,
	.zone-type, .alarm-confirmation, .package-expiration,
	.gps-equipment-beaconid, .gps-equipment-eddystoneid, .gps-equipment-imei {
		@include small-type-table;
	}
}

// Global css for tables within accordions
.accordion .content.with-table {
	padding: 0;
	table {
		border: 0;
		margin-bottom: 0;
	}
}
form .accordion {
	margin-bottom: $form-spacing;
}
form small.error.warning {
	background: $warning-color;
}
.longtext-checkbox-wrapper {
	margin-bottom: $form-spacing;
	.checkbox-wrapper {
		margin-bottom: 0;
		float: left;
		display: block;
		width: rem-calc(25);
		label.right {
			padding: 0;
			margin-left: $form-spacing; /** see _forms.scss: 454 */
			margin-right: $form-spacing * .5; /** see _forms.scss: 454 */
		}
		& + label {
			display: block;
			margin-left: rem-calc(25);
		}
	}
}
.button.microscopic { // Useful for buttons next to radios etc
	padding: 0 ($button-tny / 3) 0 ($button-tny / 3);
	margin-bottom: 0;
	vertical-align: top;
}
//////// Fixes for foundation
.button-group {
	vertical-align: top; // For some reason the display inline-flex causes a margin at the top of any buttongroup, see the one in zone-editor.html
}
.column, .columns {
	> ul.accordion {
		margin-left: 0;
	}
}
.f-dropdown {
	margin-bottom: 0;
}
:-ms-input-placeholder {
	color: $input-placeholder-font-color !important; // important is required, see http://stackoverflow.com/a/30827689
}
.f-dropdown {
	z-index: 9999 !important; // Make sure dropdowns are above the dispolight list on Firefox > 46 https://app.asana.com/0/56030549137728/132068753297624
}
// Disable some switch stuff from foundation apps, we want the pure foundation switch
.switch {
	border-radius: 0;
	width: auto; height: auto;
	> label::after {
		border-radius: 0;
		border: none;
	}
}

.mtop20 {
  margin-top: 20px;
}

.mbottom0 {
  margin-bottom: 0;
}

.time-interval-lbl {
  margin-top: 3px;
  text-align: right;
}

.time-ui-lbl {
  margin-top: 3px;
  text-align: right;
}

.column-left {
  padding-left: 0 !important;
}

.centered {
  text-align: center;
}

.select-filter {
  height: 2.2rem;
}

.title-option {
  margin-top: 1rem;
}

.always-on {
	display: block !important;
}

.print-only { // Re-set to display: initial in print.scss
	display: none;
}
/////////////////////////////
@import './infostars-design.scss';
@import './print.scss';
@import './animations.scss';
@import './components/index.scss';
@import './logbook/index.scss';
@import './falogbook/index.scss';
@import './dispolight/index.scss';
@import './youthtaxi/index.scss';
@import './fuelstar/index.scss';
@import './setup/index.scss';
@import './lists/index.scss';
@import './alarmstar/index.scss';
@import './maintenance/index.scss';
@import './zonelogbook/index.scss';
