// Allows the use of rem-calc() or lower-bound() in your settings
@import 'foundation-sites/scss/foundation/functions';


// $st-menu-bg: #48a770;
$st-menu-bg: #f9f9f9;

// Settings that depend on foundation variables, go into index.scss
$primary-color: #db6314;
$topbar-bg: linear-gradient(to bottom, #24acdc 0%,#1e72a7 100%);
$topbar-link-bg-hover: #41b9e0;
$topbar-dropdown-link-bg-hover: #41b9e0;
$topbar-dropdown-bg: #1e72a7;
$topbar-dropdown-link-bg: #1e72a7;
$table-even-row-bg: #F3F2F2;
$input-placeholder-font-color: scale-color(#666666, $lightness: 60%);

.top-bar {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#24acdc+0,1e72a7+100 */
	background: #24acdc; /* Old browsers */
	background: -moz-linear-gradient(top, #24acdc 0%, #1e72a7 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #24acdc 0%,#1e72a7 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #24acdc 0%,#1e72a7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

// Extra variables for our stylesheet
$body-font-color-dimmed: scale-color( #222222, $lightness: 60%); // #222222 is $jet is from $body-font-color
